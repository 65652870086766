@import '../../scss/utilities';

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 $grid-gutter-width $grid-gutter-width;
  position: relative;
  background-color: $ue-white;
  border-radius: 10px;
  box-shadow: 0px 0px 30px $ue-shadow;
}

.card__link {
  font-size: $ue-font-size-9;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  @each $category, $color in $category-colors {
    &_#{$category} {
      color: $color;
    }
  }
  &:hover,
  &:focus {
    color: $ue-black;
    text-decoration: none;
  }
}
